import React, { Component } from 'react';

class Skills extends Component {
    render() {
        return (
            <div data-scroll="2" className="my-skill-section sec-p100-bg-bs mb-30 clearfix" id="skill">
                <div className="Section-title">
                    <p>
                        I have a strong proficiency in both frontend and backend development, but my particular passion lies in tackling backend challenges to create scalable solutions and ensure high performance.
                        <br/>
                        Throughout my career, I have undertaken numerous personal projects, which you can find <a href="/portfolio" target="_blank" style={{color: 'blue'}}>here</a>. Additionally, during my time at university, I actively participated in events and Hackathons, achieving noteworthy accomplishments. <a href="/events" target="_blank" style={{color: 'blue'}}>Here</a> are some of the events and achievements that I take pride in.
                        <br/><br/>
                    </p>
                </div>
                <div className="text-center">
                    <button type="button" className="btn btn-sm btn-info" style={{'marginBottom':'5px'}}>#Software architecture</button>&nbsp;
                    <button type="button" className="btn btn-sm btn-info" style={{'marginBottom':'5px'}}>#Software Development</button>&nbsp;
                    <button type="button" className="btn btn-sm btn-info" style={{'marginBottom':'5px'}}>#Web Development</button>&nbsp;
                    <button type="button" className="btn btn-sm btn-info" style={{'marginBottom':'5px'}}>#Data Scientist</button>
                </div>
                <br/><br/>
                <div className="professional-skills-area">
                    <div>
                        <h2><i className="fa fa-lightbulb-o" aria-hidden="true"></i> Technical skills</h2><br/>
                        <div className="skill_progress">
                            <div className="row">
                                <div className="col l6 m6 s12">
                                    <div className="single_skill">
                                        <div className="skilled-tittle">Golang, Python, PHP, JAVA, C/C++, C#</div>
                                        <div className="progress_bar">
                                            <span data-percent="75" style={{'transition': 'width 3s', 'width': '75%'}}></span>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="single_skill">
                                        <div className="skilled-tittle">HTML, CSS, JS</div>
                                        <div className="progress_bar">
                                            <span data-percent="80" style={{'transition': 'width 3s', 'width': '80%'}}></span>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="single_skill">
                                        <div className="skilled-tittle">AWS (EC2, SQS, ECR, RDS...) / Google Cloud (Compute engine, Cloud SQL...)</div>
                                        <div className="progress_bar">
                                            <span data-percent="75" style={{'transition': 'width 3s', 'width': '75%'}}></span>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="single_skill">
                                        <div className="skilled-tittle">SQL and NoSQL databases</div>
                                        <div className="progress_bar">
                                            <span data-percent="75" style={{'transition': 'width 3s', 'width': '75%'}}></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col l6 m6 s12">
                                    <div className="single_skill">
                                        <div className="skilled-tittle">Python (Pandas, Numpy, Selenium, Scikit-learn)</div>
                                        <div className="progress_bar">
                                            <span data-percent="70" style={{'transition': 'width 3s', 'width': '70%'}}></span>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="single_skill">
                                        <div className="skilled-tittle">Symfony, Laravel, Angular, ReactJS, Ionic, Flask, Django, Node</div>
                                        <div className="progress_bar">
                                            <span data-percent="60" style={{'transition': 'width 3s', 'width': '60%'}}></span>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="single_skill">
                                        <div className="skilled-tittle">Docker</div>
                                        <div className="progress_bar">
                                            <span data-percent="70" style={{'transition': 'width 3s', 'width': '70%'}}></span>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="single_skill">
                                        <div className="skilled-tittle">Tensorflow, Keras</div>
                                        <div className="progress_bar">
                                            <span data-percent="50" style={{'transition': 'width 3s', 'width': '50%'}}></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/><br/>
                <div className="professional-skills-area">
                    <div>
                        <h2>
                            <i className="fa fa-lightbulb-o" aria-hidden="true"></i> Other skills
                        </h2>
                        <br/>
                        <div className="skill_progress">
                            <div className="row">
                                <div className="col l6 m6 s12">
                                    <div className="single_skill">
                                        <div className="skilled-tittle">Scrum</div>
                                        <div className="progress_bar">
                                            <span data-percent="80" style={{'transition': 'width 3s', 'width': '80%'}}></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col l6 m6 s12">
                                    <div className="single_skill">
                                        <div className="skilled-tittle">Modeling (UML/Merise)</div>
                                        <div className="progress_bar">
                                            <span data-percent="70" style={{'transition': 'width 3s', 'width': '70%'}}></span>
                                        </div>
                                    </div>
                                    <br/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Skills;
