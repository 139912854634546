import React, { Component } from 'react';

class Home extends Component {
    render() {
        return (
            <div className="row justify-content-center">
                <div className="col-lg-10">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="about_thumb">
                                <img src="/images/Hosni.jpeg" alt="img" />
                            </div>
                            <div className="text-center" style={{'marginTop': '20px'}}>
                                <a href="http://www.linkedin.com/in/hosni-mansour" rel="noopener noreferrer" target="_blank" className="btn btn-primary"><i className="fa fa-linkedin"></i></a>&nbsp;
                                <a href="https://github.com/HosniMansour" rel="noopener noreferrer" target="_blank" className="btn btn-info"><i className="fa fa-github"></i></a>&nbsp;
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="about_info">
                                <h2>Hello!</h2>
                                <p>I'm <strong>Hosni Mansour</strong>, a passionate software engineer with a strong interest in IT and new technologies. I thrive on solving challenging problems and building innovative solutions.
                                <br/>My main focus is on creating clean, high-quality code, prioritizing its value over fixating on frameworks and programming languages. I believe in the importance of writing code that is easy to understand, maintain, and scale.<br/>
                                    I also have a strong interest in Machine Learning and Deep Learning. I continuously pursue opportunities to expand my knowledge and stay up-to-date with the latest advancements in these areas.</p>
                                <ul className="info_list">
                                    <li><strong>NAME:</strong><span>Hosni Mansour</span></li>
                                    <li><strong>Email: </strong><span>mans.hosni@gmail.com</span></li>
                                    <li><strong>Age:</strong><span>{((new Date().getFullYear()) - 1995)}</span></li>
                                    <li><strong>Address:</strong><span>Darmstadt, Germany</span></li>
                                </ul>
                                <a href="/Hosni_Mansour_Resume.pdf" target="_blank" className="btn btn-success" style={{float: 'right'}}><i className="fa fa-download"></i> Download CV</a>&nbsp;
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
