import React from 'react';
import {Route, Switch} from 'react-router-dom';

import Home from '../components/home'
import Resume from '../components/resume'
import Skills from '../components/skills'
import Portfolio from '../components/portfolio'
import PortfolioPage from '../components/portfolioPage'
import Contact from '../components/contact'
import Events from "../components/events";

export default () => (

        <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/resume" exact component={Resume} />
            <Route path="/skills" exact component={Skills} />
            <Route path="/events" exact component={Events} />
            <Route path="/portfolio" exact component={Portfolio} />
            <Route path="/portfolio/:id" exact component={PortfolioPage} />
        </Switch>

);