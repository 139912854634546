import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import data from '../data/projects';

class Portfolio extends Component {

    componentDidMount () {
        const scripts = ["/js/popper.min.js","/js/plugins.js","/js/jquery.appear.js","/js/main.js"];
        scripts.forEach(function(element) {
            const script = document.createElement("script");
            script.src = element;
            script.async = true;
            document.body.appendChild(script);
        });
    }

    render() {

        return (
            <div>
                <div className="row">
                    <div className="col-12">
                        <div className="section_title_area text-center pb-70">
                            <h2>Portfolio</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <ul className="portfolio_work_nav">
                            <li><a href="" data-filter="*" className="filter active">All</a></li>
                            <li><a href="" data-filter=".web" className="filter">Web</a></li>
                            <li><a href="" data-filter=".software" className="filter">Software</a></li>
                            <li><a href="" data-filter=".mobile" className="filter"> Mobile App</a></li>
                            <li><a href="" data-filter=".ml" className="filter"> Machine Learning</a></li>
                            <li><a href="" data-filter=".others" className="filter"> Others</a></li>
                        </ul>
                    </div>
                </div>
                <div id="masonry" className="row justify-content-center">
                    {data.map((o,i) => {
                        return (
                            <div key={o.id} className={`col-lg-4 col-md-4 ${o.cats}`}>
                                <div className="single_work">

                                    <Link to={ {pathname: "/portfolio/" + o.id, myProps:o }}>
                                        <img src={o.img} alt="others" />
                                        <div className="overlay_icon"><i className="fa fa-search-plus"></i></div>
                                    </Link>
                                    <div className="work_info">
                                        <h3>{o.title}</h3>
                                        <p>{o.cat}</p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default Portfolio;
