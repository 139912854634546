import React, { Component } from 'react';

class Resume extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-sm-6">
                    <div data-scroll="3" className="clearfix" id="experience">
                        <div className="Section-title">
                            <h2>
                                <i className="fa fa-briefcase"></i> Experience
                            </h2>
                        </div>
                        <br/>
                        <ul className="accordion">
                            <li>
                                <div className="accordion-header acco-clr3">
                                    <p>
                                        <i className="fa fa-briefcase" aria-hidden="true"></i> <strong>Software Developer</strong> - [BidX] , Oct 2019 - Present <br/><i className="fa fa-globe" aria-hidden="true"></i> Darmstadt, Germany
                                    </p>
                                </div>
                                <br/>
                                <div className="accordion-body">
                                    <span>
                                        - Managed Amazon Advertising API and Amazon Selling Partner API for seamless integration with internal systems, optimizing business operations.<br/>
                                        - Managed and maintained the AWS infrastructure, ensuring scalability and reliability of services for optimal performance.<br/>
                                        - Collaborated on product development, mentoring the team, and achieving successful project delivery.<br/>
                                        - Led critical feature development, enhancing product functionality and user experience.<br/>
                                        - Refactored code from PHP to Go, improving performance and maintainability.<br/>
                                        - Developed modular applications using Go, PHP, Python, Symfony/Laravel.<br/>
                                        - Leveraged AWS services (EC2, EBS, ECR, SNS/SQS, S3) and Docker for efficient deployment.<br/><br/>

                                        - Proud to mention that with BidX we were mentioned in the <a href="https://glassdollar.com/top-100-startups-germany" style={{color: 'blue'}} target="_blank">Top 100 Fastest Growing Startups in Germany</a> and we started expanding to the US with the <a href="https://www.germanaccelerator.com/blog/meet-the-21-german-startups-beginning-their-international-expansion-virtually-this-fall/" style={{color: 'blue'}} target="_blank">German Accelerator program</a>.
                                    </span>
                                </div>
                            </li>
                            <br/>
                            <li>
                                <div className="accordion-header acco-clr3">
                                    <p>
                                        <i className="fa fa-briefcase" aria-hidden="true"></i> <strong>Artificial Intelligence & Machine Learning Engineer</strong> - [Talan] <br/>Feb - Sep 2019 <br/><i className="fa fa-globe" aria-hidden="true"></i> Tunis, Tunisia
                                    </p>
                                </div>
                                <br/>
                                <div className="accordion-body">
                                    <span>Aug-Sep: [Full-time] Work on a retail recommendation system using Python libraries for data cleaning and Keras for deep learning.</span><br/><br/>
                                    <span>Feb-Jun: [Internship] Developed an intelligent border control system leveraging machine learning and deep learning techniques, utilizing Python for the machine learning components. Implemented a prototype using ReactJS and Django to showcase the integration of the machine learning models.</span>
                                </div>
                            </li>
                            <br/>
                            <li>
                                <div className="accordion-header acco-clr3">
                                    <p>
                                        <i className="fa fa-briefcase" aria-hidden="true"></i> <strong>Full Stack Developer Intern</strong> - [TrustiT Tunisia] , Jul - Aug 2018 <br/><i className="fa fa-globe" aria-hidden="true"></i> Tunis, Tunisia
                                    </p>
                                </div>
                                <br/>
                                <div className="accordion-body">
                                    <span>Designed and developed a cutting-edge freelancer website using modern web technologies such as Express/NodeJS for the backend, React for the frontend, and MongoDB for the database.</span>
                                </div>
                            </li>
                            <br/>
                            <li>
                                <div className="accordion-header acco-clr3">
                                    <p>
                                        <i className="fa fa-briefcase" aria-hidden="true"></i> <strong>Full Stack Developer Intern</strong> - [Click2web] , Aug 2017 <br/><i className="fa fa-globe" aria-hidden="true"></i> Sousse, Tunisia
                                    </p>
                                </div>
                                <div className="accordion-body">
                                    <span>Built a travel agency website with PHP, HTML, and CSS.</span>
                                </div>
                            </li>
                            <br/>
                            <li>
                                <div className="accordion-header acco-clr3">
                                    <p>
                                        <i className="fa fa-briefcase" aria-hidden="true"></i> <strong>Web and Android Developer Intern</strong> - [MediaPlus] , Jan – Apr 2016 <br/><i className="fa fa-globe" aria-hidden="true"></i> Monastir, Tunisia
                                    </p>
                                </div>
                                <div className="accordion-body">
                                    <span>Created E-commerce website using Symfony3 Framework.</span>
                                </div>
                            </li>
                            <br/>
                        </ul>
                    </div>
                </div>

                <div className="col-sm-6">
                    <div data-scroll="3" className="clearfix" id="education">
                        <div className="Section-title">
                            <h2>
                                <i className="fa fa-graduation-cap"></i> Education
                            </h2>
                        </div>
                        <br/>
                        <ul className="accordion">
                            <li>
                                <div className="accordion-header acco-clr3">
                                    <p>
                                        <i className="fa fa-pencil" aria-hidden="true"></i> <strong>Master's degree in Software engineering</strong> - ISAMM: Higher Institute of Multimedia of Manouba , 2016 - 2019
                                    </p>
                                </div>
                                <div className="accordion-body">
                                    <span> Computer science and multimedia.</span>
                                </div>
                            </li>
                            <br/>
                            <li>
                                <div className="accordion-header acco-clr3">
                                    <p>
                                        <i className="fa fa-pencil" aria-hidden="true"></i> <strong>Bachelor's degree in computer science</strong> - ISIMM: Higher Institute of Computer Sciences and Mathematics of Monastir , 2013 - 2016
                                    </p>
                                </div>
                                <div className="accordion-body">
                                    <span>Computer System and Software.</span>
                                </div>
                            </li>
                            <br/>
                        </ul>
                    </div>
                    <br/>
                    <div data-scroll="3" className="clearfix" id="certification">
                        <div className="Section-title">
                            <h2>
                                <i className="fa fa-newspaper-o"></i> Certification
                            </h2>
                        </div>
                        <br/>
                        <ul className="accordion">
                            <li>
                                <div className="accordion-header acco-clr3">
                                    <p>
                                        <i className="fa fa-file-text-o" aria-hidden="true"></i> <strong>SAA-C02</strong> AWS Certified Solutions Architect – Associate , Mar 2022
                                    </p>
                                </div>
                            </li>
                            <br/>
                            <li>
                                <div className="accordion-header acco-clr3">
                                    <p>
                                        <i className="fa fa-file-text-o" aria-hidden="true"></i> <strong>MTA 98-383</strong> Introduction to Programming Using HTML and CSS , Jun 2018
                                    </p>
                                </div>
                            </li>
                            <br/>
                            <li>
                                <div className="accordion-header acco-clr3">
                                    <p>
                                        <i className="fa fa-file-text-o" aria-hidden="true"></i> <strong>MTA 98-382</strong> Introduction to Programming Using JavaScript , Jun 2018
                                    </p>
                                </div>
                            </li>
                            <br/>
                            <li>
                                <div className="accordion-header acco-clr3">
                                    <p>
                                        <i className="fa fa-file-text-o" aria-hidden="true"></i> <strong>MTA 98-367</strong> Security Fundamentals , Jun 2018
                                    </p>
                                </div>
                            </li>
                            <br/>
                            <li>
                                <div className="accordion-header acco-clr3">
                                    <p>
                                        <i className="fa fa-file-text-o" aria-hidden="true"></i> <strong>MTA 98-388</strong> Introduction to Programming using Java , Jun 2018
                                    </p>
                                </div>
                            </li>
                            <br/>
                            <li>
                                <div className="accordion-header acco-clr3">
                                    <p>
                                        <i className="fa fa-file-text-o" aria-hidden="true"></i> <strong>MTA 98-381</strong> Introduction to Programming Using Python , May 2018
                                    </p>
                                </div>
                            </li>
                            <br/>
                            <li>
                                <div className="accordion-header acco-clr3">
                                    <p>
                                        <i className="fa fa-file-text-o" aria-hidden="true"></i> <strong>MTA 98-361</strong> Software Development Fundamentals C# , Apr 2017
                                    </p>
                                </div>
                            </li>
                            <br/>
                        </ul>

                        <div style={{'marginBottom': '20px'}}>
                            * Badges could be found <a href="https://www.credly.com/users/hosni-mansour/badges" rel="noopener noreferrer" target="_blank" style={{color: 'blue'}}>here</a>.
                        </div>

                        <div style={{'marginBottom': '20px'}}>
                            * MTA certificate verification <a href="https://mcp.microsoft.com/Anonymous//Transcript/Validate" rel="noopener noreferrer" target="_blank" style={{color: 'blue'}}>mcp.microsoft.com</a> : Transcript ID (1222901) and the Access Code (00000000).
                        </div>

                    </div>
                </div>

            </div>
        );
    }
}

export default Resume;
