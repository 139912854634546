import React, { Component } from 'react';

class Contact extends Component {
    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-12">
                        <div className="section_title_area text-center pb-70">
                            <h2>Contact</h2>
                            <p><span>Feel free to contact me.</span></p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <form id="contact_form" className="contact_form align-items-center" name="contactForm" method="post" action="/contact.php">
                                <div className="row">
                                    <div className="col-lg-6 pr-15">
                                        <p><input name="name" id="name" aria-required="true" placeholder="Name *" className="form-controllar" type="text" /></p>
                                    </div>
                                    <div className="col-lg-6">
                                        <p><input name="email" id="email" aria-required="true" placeholder="Email *" className="form-controllar email" type="text" /></p>
                                    </div>
                                    <div className="col-lg-12">
                                        <p><input placeholder="Sub: of the email *" aria-required="true" id="text" name="subject" className="form-controllar" type="text" /></p>
                                    </div>
                                    <div className="col-lg-12">
                                        <p><textarea name="message" id="message" aria-required="true" rows="5" cols="45" placeholder="Your message" className="form-controllar" />
                                        </p>
                                    </div>
                                    <div className="col-lg-12 text-center">
                                        <button type="submit" className="btn btn-dark pull-right">submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;
