import React, { Component } from 'react';
import Gallery from 'react-grid-gallery';
import IMAGES from '../data/images';

class Events extends Component {

    render() {
        return (
            <div>
                <div className="accordion-body">
                    <span>
                         I like attending events and participate in hackathons, it's a way to know new people, learn more about technologies and have fun! The pictures bellow are some of my favorite events.
                    </span>
                </div>
                <br/><br/>
                <div className="gallery">
                <Gallery enableImageSelection={false} images={IMAGES}/>
                </div>
                <br/>
            </div>
        );
    }
}

export default Events;
