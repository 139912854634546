import React, {Component} from 'react';
import Routes from './routes/index';
import {NavLink, BrowserRouter as Router} from 'react-router-dom';

class App extends Component {
    render() {
        return (
            <Router>
            <div>
                <header>
                    <div id="sticky_menu" style={{display: 'block'}}>
                        <div className="site_navigation">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <nav className="navbar navbar-expand-lg navigation_area">
                                            <a> </a>
                                            <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                                <span className="navbar-toggler-icon"></span>
                                            </button>
                                            <div className="collapse navbar-collapse mainmenu_area" id="navbarNav">
                                                <ul className="navbar-nav mainmenu">
                                                    <li className="nav-item"><NavLink className="nav-link" exact to="/">Home</NavLink></li>
                                                    <li className="nav-item"><NavLink className="nav-link" exact to="/resume">Resume</NavLink></li>
                                                    <li className="nav-item"><NavLink className="nav-link" exact to="/skills">Skills</NavLink></li>
                                                    <li className="nav-item"><a className="nav-link" href="https://blog.hosni.me" rel="noopener noreferrer" target="_blank">Blog</a></li>
                                                </ul>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="site_wrapper bg_white">
                    <section id="about" className="about_area section_scroll ptb-110">
                        <div className="container" style={{'marginTop': '6%'}}>
                            <Routes />
                        </div>
                    </section>
                </div>
            </div>
            </Router>
        );
    }
}

export default App;
