import React, { Component } from 'react';
import data from '../data/projects';
import {Link} from 'react-router-dom';

class PortfolioPage extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        const match = this.props.match.params.id;
        const project = data.find( function(item) { return item.id == match } );
        return (
            <div className="post-inner">
                <div className="row">
                    <div className="col-sm-8">
                        <h2 className="post-title">{project.title}</h2>
                    </div>
                    <div className="col-sm-4">
                        <Link className="btn btn-info" to="/portfolio" style={{'float': 'right'}}><i className="icon-link"></i> Back to list</Link>
                    </div>
                </div>
                <br/><br/>
                <div className="text-center">#{project.cat}</div>
                <br/><br/>
                <div className="row">
                    <div className="text-center col-md-8 offset-md-2">
                        <div dangerouslySetInnerHTML={{__html: project.desc}} />
                    </div>
                </div>
            </div>
        );
    }
}

export default PortfolioPage;
